import App from './App.svelte'

const app = new App({
  target: document.body,
  props: {
    alive: true,
  },
})

export default app
