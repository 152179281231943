<script>
  import Title from './Title.svelte'
</script>

<style>
  main {
    align-content: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 2rem;
  }

  table {
    border-collapse: collapse;
    margin: auto;
    max-width: 660px;
    table-layout: fixed;
    text-align: center;
    width: 90%;
  }

  td {
    padding-bottom: 1rem;
    padding-top: 1rem;
    vertical-align: middle;
  }

  img {
    height: auto;
    width: 100%;
  }

  @media (min-width: 480px) {
    table {
      width: 75%;
    }
  }

  @media (min-width: 768px) {
    table {
      width: 60%;
    }
  }

  @media (min-width: 1200px) {
    table {
      width: 50%;
    }
  }

  @media (min-width: 1400px) {
    table {
      width: 45%;
    }
  }

  @media (min-width: 768px) and (max-height: 1024px) {
    table {
      width: 500px;
    }
  }

  @media (min-width: 768px) and (max-height: 860px) {
    table {
      width: 450px;
    }
  }

  @media (min-width: 768px) and (max-height: 768px) {
    table {
      width: 400px;
    }
  }
</style>

<main>
  <table>
    <thead>
      <tr>
        <th>
          <Title ratio={0.78}>giggy is dead</Title>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <a
            href="https://teespring.com/giggy-dead?tsmac=store&tsmic=giggy-status&pid=2"
            target="_blank"
            rel="noopener">
            <img src="/giggy-dead.gif" width="666" height="860" alt />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</main>
