<script>
  import wait from 'waait'
  import { onMount, onDestroy } from 'svelte'

  export let title
  export let ratio = 1
  export let min = null
  export let max = 120
  export let disabled = false

  const fit = async () => {
    if (disabled) {
      title.style.opacity = '1'
      title.style.fontSize = ''
      title.style.transition = ''
      return
    }
    let size = title.clientWidth / (ratio * 10)
    if (max) {
      size = Math.min(size, max)
    }
    if (min) {
      size = Math.max(size, min)
    }
    title.style.fontSize = `${size}px`
  }

  onMount(async () => {
    fit()
    await wait(25)
    fit()
    window.addEventListener('resize', fit)
    window.addEventListener('orientationchange', fit)
  })

  onDestroy(() => {
    window.removeEventListener('resize', fit)
    window.removeEventListener('orientationchange', fit)
  })
</script>

<style>
  h1 {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    width: 100%;
    white-space: nowrap;
  }
</style>

<h1 bind:this={title}>
  <slot />
</h1>
